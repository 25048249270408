<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="243" height="50" viewBox="0 0 243 50" fill="none">
    <g id="Group">
      <g id="Group_2">
        <path id="Vector" d="M64.6021 13.2477H54.4209V48.1409H64.6021V13.2477Z" fill="#00539E"/>
        <path id="Vector_2" d="M64.6019 0.857142H54.4207V9.60323H64.6019V0.857142Z" fill="#00539E"/>
      </g>
      <path id="Vector_3" d="M85.3179 12.6099C83.2817 13.0655 80.0059 16.6186 78.4124 18.5318V13.2477H68.1426V48.232H78.3238C78.3238 48.232 78.3238 29.2822 78.3238 28.0067C78.3238 26.7312 81.1569 22.5404 84.4326 22.3582C87.3542 22.2671 90.0987 22.176 90.0987 22.176V12.1544C90.1872 12.1544 87.9739 11.9722 85.3179 12.6099Z" fill="#00539E"/>
      <path id="Vector_4" d="M122.679 44.0407C122.679 42.4008 122.679 23.0865 122.679 20.6267C122.679 18.1668 119.846 12.1539 107.629 12.1539C95.411 12.1539 91.25 20.08 91.25 24.8175H100.812C100.812 24.8175 101.874 20.3533 105.061 20.1711C107.806 19.9889 108.779 20.1711 109.753 20.1711C110.727 20.1711 113.206 21.4466 113.206 23.6331C113.206 25.8196 112.675 26.4574 107.717 27.004C102.759 27.5506 89.8335 29.0994 89.8335 39.121C89.8335 49.1425 98.9524 49.1425 102.14 49.1425C105.327 49.1425 111.701 46.2272 113.118 44.8606C113.118 44.8606 113.649 47.776 114.534 48.1404H125.335V47.0471C125.424 47.0471 122.679 46.1361 122.679 44.0407ZM113.295 37.2989C113.295 38.7566 109.93 42.6741 104.53 42.6741C101.166 42.6741 99.8377 40.4876 99.8377 38.301C99.8377 36.1145 103.29 33.7458 106.832 33.1081C110.285 32.4703 111.081 32.4703 113.295 31.9237C113.295 31.9237 113.295 35.8412 113.295 37.2989Z" fill="#00539E"/>
      <path id="Vector_5" d="M159.421 20.1722V12.5193H129.143V20.1722H146.318L127.637 40.4886V48.2325H160.129V40.4886H140.829L159.421 20.2633V20.1722Z" fill="#00539E"/>
      <path id="Vector_6" d="M221.038 12.2457C216.257 12.2457 212.45 14.8877 209.971 17.1653V13.2478H199.79V48.2322H209.971V26.367C210.679 24.0893 212.45 20.354 217.939 20.354C223.339 20.354 223.871 24.7271 223.871 26.8225V48.2322H234.052V29.2823V26.8225C234.052 25.8203 234.052 24.3626 234.052 22.3583C234.052 16.7098 228.651 12.2457 221.038 12.2457Z" fill="#00539E"/>
      <path id="Vector_7" d="M42.8216 1.31268H41.7592H40.3427H34.9422L25.6463 38.2102L16.3504 1.31268H11.0384H9.62191H8.55951H0.857178V48.2318H11.0384V11.6076L19.9802 48.2318H23.9642H25.4692H25.8234H26.6202H31.3124L40.3427 11.4253V48.2318H50.524V1.31268H42.8216Z" fill="#00539E"/>
      <path id="Vector_8" d="M178.012 11.6992C168.008 11.6992 159.951 19.9897 159.951 30.2846C159.951 30.7401 159.951 31.1046 160.04 31.469C160.66 23.2695 167.3 16.71 175.445 16.71C184.032 16.71 190.938 23.8162 190.938 32.6534C190.938 41.3994 184.032 48.5967 175.533 48.5967C176.33 48.6878 177.215 48.7789 178.012 48.7789C188.016 48.7789 196.073 40.4884 196.073 30.1935C196.073 19.8986 188.016 11.6992 178.012 11.6992Z" fill="#FAA627"/>
      <g id="Group_3">
        <g id="Group_4">
          <path id="Vector_9" d="M238.21 9.60392C235.996 9.60392 234.226 7.78182 234.226 5.50419C234.226 3.22657 235.996 1.40447 238.21 1.40447C240.423 1.40447 242.194 3.22657 242.194 5.50419C242.194 7.78182 240.423 9.60392 238.21 9.60392ZM238.21 2.13331C236.35 2.13331 234.934 3.68209 234.934 5.50419C234.934 7.4174 236.439 8.87508 238.21 8.87508C239.98 8.87508 241.485 7.32629 241.485 5.50419C241.574 3.59099 240.069 2.13331 238.21 2.13331Z" fill="#00539E"/>
        </g>
        <g id="Group_5">
          <path id="Vector_10" d="M237.59 5.77631V7.4162H236.793V3.13426H238.033C238.299 3.13426 238.564 3.13426 238.741 3.22537C238.918 3.31647 239.095 3.40758 239.272 3.49868C239.361 3.58979 239.538 3.772 239.538 3.8631C239.538 3.95421 239.627 4.13642 239.627 4.31863C239.627 4.50084 239.627 4.59194 239.538 4.68305C239.538 4.77415 239.449 4.95636 239.361 5.04747C239.272 5.13857 239.184 5.22968 239.095 5.32079C239.007 5.41189 238.83 5.503 238.741 5.503C238.83 5.5941 238.918 5.68521 239.007 5.77631L240.069 7.3251H239.361C239.272 7.3251 239.272 7.3251 239.184 7.3251C239.095 7.3251 239.095 7.23399 239.095 7.23399L238.21 5.86742C238.21 5.77631 238.121 5.77631 238.121 5.77631C238.121 5.77631 238.033 5.77631 237.944 5.77631H237.59ZM237.59 5.22968H238.033C238.21 5.22968 238.299 5.22968 238.387 5.13857C238.476 5.13857 238.564 5.04747 238.653 4.95636C238.741 4.86526 238.741 4.77415 238.83 4.68305C238.918 4.59194 238.918 4.50084 238.918 4.40973C238.918 4.22752 238.83 4.04531 238.741 3.95421C238.564 3.8631 238.387 3.772 238.121 3.772H237.59V5.22968Z" fill="#00539E"/>
        </g>
      </g>
    </g>
  </svg>
</template>
